import { createStore } from 'vuex'
import { setLocal, getLocal } from '@/utils/utils'

export default createStore({
  state() {
    return {
      isLogin:0,
      token:"",
      nickName:"",
      sid:"",
      cooperid:"",
      rootnumid:"",
      headImgPath:"",
      email:"",
      vip:0,
      sids:[],
      coin:0,
      rp:0,
      lastBindNo:"",
      lastBindPaytype:"",
      bindInfos:[]
    } 
  },
  mutations: {
    addSid(state,sid){
      let hasSame = false
      for (let index = 0;  index < state.sids.length ; index++) {
        const element = state.sids[index] 
        if (element == String(sid)){
          hasSame = true
          return
        }
      }
      state.sids.unshift(sid)
      setLocal("sids",state.sids)
    },
    setVip(state,email) {
      state.email = email
    },
    setVip(state,vip) {
      state.vip = vip
    },
    setCoin(state,coin) {
      state.coin = coin
    },
    setRp(state,rp) {
      state.rp = rp
    },
    setEmail(state,email) {
      state.email = email
    },
    setIsLogin(state,isLogin) {
      state.isLogin = isLogin
    },
    setToken(state,token) {
      setLocal("token",token)
      state.token = token
    },
    setNickName(state,nickName) {
      state.nickName = nickName
    },
    setSid(state,sid) {
      state.sid = sid
    },
    setCooperid(state,cooperid) {
      state.cooperid = cooperid
    },
    setRootnumid(state,rootnumid) {
      state.rootnumid = rootnumid
    },
    setHeadImagePath(state,headImgPath) {
      state.headImgPath = headImgPath
    },
    clearUser(state){
      setLocal("token","")
      state.isLogin = 0
      state.token = ""
      state.nickName = ""
      state.sid = ""
      state.cooperid = ""
      state.rootnumid = ""
      state.vip = 0
      state.headImgPath = ""
      state.email = ""
      state.coin = 0
      state.rp = 0
      state.lastBindNo = ""
      state.lastBindPaytype = ""
    },
    setLastBindNo(state,lastBindNo) {
      state.lastBindNo = lastBindNo
      setLocal(state.sid + "___lastBindNo",lastBindNo)
    },
    setLastBindPaytype(state,lastBindPaytype) {
      state.lastBindPaytype = lastBindPaytype
      setLocal(state.sid + "___lastBindPaytype",lastBindPaytype)
    },
    setBindInfos(state,bindInfos) {
      state.bindInfos = bindInfos
      setLocal(state.sid + "___bindInfos",JSON.stringify(state.bindInfos))
    },
  },
  actions: {
  },
  getters: {
    getLastBindInfos(state){
      let bindInfos = getLocal(state.sid + "___bindInfos")
      if (bindInfos == null || bindInfos == ""){
        bindInfos = []
      }else{
        bindInfos = JSON.parse(bindInfos)
      }
      console.log("bindInfos",bindInfos)
      state.bindInfos = bindInfos
      return state.bindInfos 
    },
    getLastBindNo(state){
      let lastBindNo = getLocal(state.sid + "___lastBindNo")
      if (lastBindNo == null){
        lastBindNo = ""
      } 
      state.lastBindNo = lastBindNo
      return state.lastBindNo 
    },
    getLastBindPaytype(state){
      let lastBindPaytype = getLocal(state.sid + "___lastBindPaytype")
      if (lastBindPaytype == null){
        lastBindPaytype = ""
      } 
      state.lastBindPaytype = lastBindPaytype
      return state.lastBindPaytype 
    },
    getEmail(state) {
      return state.email 
    },
    getIsLogin(state) {
      return state.isLogin 
    },
    getToken(state){
      let token = getLocal("token")
      if (token == null){
          token = ""
      } 
      state.token = token
      return state.token 
    },
    getNickName(state){
      return state.nickName 
    },
    getSid(state){
      return state.sid 
    },
    getCooperid(state){
      return state.cooperid 
    },
    getRootnumid(state){
      return state.rootnumid 
    },
    getVip(state){
      return state.vip 
    },
    getCoin(state){
      return state.coin 
    },
    getRp(state){
      return state.rp 
    },
    getHeadImagePath(state){
      return state.headImgPath 
    },
    getSids(state) {
      let sidsStr = getLocal("sids")
      if (sidsStr == null){
        state.sids = []
      }else{
        let sids = sidsStr.split(",")
        let sids2 = []
        for (let index = 0; index < sids.length; index++) {
          const element = sids[index];
          if(element && element != ""){
            sids2.push(element)
          }
        }
        state.sids = sids2
      }
      return state.sids 
    },
  },
}) 
