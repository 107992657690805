import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import  store  from './store'
import 'amfe-flexible'
import en from './locales/en.json' // Import your language files
import id from './locales/id.json'
import { createI18n } from 'vue-i18n'
import 'vant/lib/index.css'
import { Toast } from 'vant'
import mitt from 'mitt'

const bus = mitt()

const messages = {
    en: en,
    id: id
}

const i18n = new createI18n({
    locale: 'id', // Set the default locale
    messages
})

const app = createApp(App)
app.config.globalProperties.$bus = bus
app.use(router)
app.use(store)
app.use(i18n)
app.use(Toast)
app.mount('#app')


