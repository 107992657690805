import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'main',
    },
  },
  {
    name: 'main',
    path: '/main',
    component: () => import('./view/main'),
    meta: {
      title: 'Saku Game',
    },
    children:[
      {
        name: 'home',
        path: '/',
        component: () => import('./view/gamecenter'),
      },
      {
        name: 'activity',
        path: '/activity',
        component: () => import('./view/activity'),
      },
      {
        name: 'promotion',
        path: '/promotion',
        component: () => import('./view/promotion'),
      },
      {
        name: 'user',
        path: '/user',
        component: () => import('./view/user'),
      },
    ]
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
})

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }
  next()
})

export { router }
